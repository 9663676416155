import {useEffect, useState} from 'react';

import {TVarifyContext, TVarifyParsedObject, TVarifyTestObject, TVarifyVariationStore} from './VarifyContext';

// Extend the Window interface to include varify
declare global {
  interface Window {
    varify?: VarifyType;
  }
}

// Define the VarifyType interface with nested types for each property
interface VarifyType {
  iid: number;
  debug: DebugType;
  helpers: Record<string, unknown>;
  loaded: boolean;
}

interface DebugType {
  appliedExperiments: Record<string, unknown>;
  experiments: ExperimentType[];
}

interface ExperimentType {
  id: number;
  slug: string;
  url_targeting: URLTargetingType;
  audience_targeting: string | null;
  should_cleanup: boolean;
  tracking_enabled: boolean;
  variations: VariationType[];
}

interface URLTargetingType {
  condition: 'any' | 'all';
  routes: RouteType[];
}

interface RouteType {
  route: string;
  match_type: 'regex' | 'contains';
}

interface VariationType {
  id: number;
  slug: string;
  traffic_allocation: number; // percentage as integer (0-100)
}

export const useInitVarify = () => {
  const [experimentsStore, setExperimentsStore] = useState<TVarifyVariationStore>({});
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    const handleStorageChange = () => {
      console.log('storage change')
      try {
        const preview = new URL(window.location.href).searchParams.get('varify-preview');
        // if(!window?.varify?.loaded) {
        //   return false;
        // }
        if(preview) {
          return true;
        }
        if(!preview) {
          
          const experiments: TVarifyVariationStore = {};
          
          // Loop through all keys in localStorage
          for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            
            // Check if the key matches 'varify-experiment-<id>'
            if (key && key.startsWith('varify-experiment-')) {
              // Extract the id by removing the prefix 'varify-experiment-'
              const id = key.replace('varify-experiment-', '');
              const varifyTestObject: TVarifyTestObject = {
                id,
                variationId: null,
                isVariation: false,
              };
              const testObject = JSON.parse(localStorage.getItem(key)) as TVarifyParsedObject;
              varifyTestObject.variationId = testObject?.variationId;
              if (testObject?.variationId !== null) {
                varifyTestObject.isVariation = true;
              }
              experiments[id] = varifyTestObject;
            }
          }
          
          // Update the state only if there are changes to avoid unnecessary renders
          setExperimentsStore(prevExperiments => {
            if (JSON.stringify(prevExperiments) !== JSON.stringify(experiments)) {
              return experiments;
            }
            return prevExperiments;
          });
          return true;
        }
      } catch (err) {
        console.log('Could not parse Varify object');
      }
    };
    
    // Execute preview logic only once on mount
    const initializePreviewExperiment = () => {
      if(!window?.varify?.loaded) {
        return false;
      }
      const preview = new URL(window.location.href).searchParams.get('varify-preview');
      if (preview && preview.match(/(\d+)-variation/)) {
        const matches = preview.match(/(\d+)-variation/);
        let variationId;
  
        if (matches && matches[1]) {
          variationId = matches[1];
        }
  
        const availableExperiments = window.varify?.debug?.experiments;
        const experimentId = availableExperiments?.find((experiment: ExperimentType) => {
          return experiment.variations.some((variation: VariationType) => variation.id === parseInt(variationId));
        })?.id;
  
        if (experimentId) {
          const varifyTestObject: TVarifyTestObject = {
            id: experimentId.toString(),
            variationId: parseInt(variationId),
            isVariation: true,
          };
          setExperimentsStore(prevExperiments => ({
            ...prevExperiments,
            [experimentId]: varifyTestObject,
          }));
        }
      }
      return true;
    };
    
    // Add event listeners to detect changes in localStorage
    window.addEventListener('storage', handleStorageChange);
  
    // Initial calls to load current localStorage experiments and set preview
    handleStorageChange();
    let intervalCount = 0;
    const interval = setInterval(() => {
      if(intervalCount >= 10000 || initializePreviewExperiment() && handleStorageChange()) {
        intervalCount+=100;
        clearInterval(interval);
      }
    }, 100)
    initializePreviewExperiment();
    
    // Cleanup event listener on component unmount
    return () => {
      clearInterval(interval);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  
  return {experimentsStore, initialized} as TVarifyContext;
};
