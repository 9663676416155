// contexts/TranslationToggleContext.js
import React, { createContext, useContext, useState } from 'react';

const TranslationToggleContext = createContext<{showKeys?: boolean, setShowKeys?: React.Dispatch<React.SetStateAction<boolean>>}>({});

export const TranslationToggleProvider = ({ children }) => {
  const [showKeys, setShowKeys] = useState(false);

  return (
    <TranslationToggleContext.Provider value={{ showKeys, setShowKeys }}>
      {children}
    </TranslationToggleContext.Provider>
  );
};

export const useTranslationToggle = () => useContext(TranslationToggleContext);
