import {useTranslation} from 'hooks/translations/useCustomTranslation';
import Image from 'next/image';

import Box from '../Box';
import Typography from '../Typography';
import Car from './car_animation.webp';
import styles from './styles.module.scss';

export const LoadingCarAnimation = ({text}: {text?: string}) => {
  const {t} = useTranslation('common');
  return (
    <Box className={styles.wrapper}>
      <Image
        layout="cover"
        className={styles.image}
        width={Car.height}
        height={Car.width}
        sizes="100vw"
        // style={{width: '200px', height: 'auto'}}
        alt="loading-car-animation"
        src={Car.src}
      />
      <Typography className={styles.loading} component={'p'} variant="h2">
        {text ? text : t('spinnerLoadingText')}
      </Typography>
    </Box>
  );
};
