var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Oyn9zQpUbdi0De56DxTag"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';


const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://e1d27429913b4d45b7c9346065e2ca7b@o4505090782461952.ingest.sentry.io/4505092068540416',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production-next' ? 0.5 : 1.0,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'production-next',
  ignoreErrors: ["Cannot read properties of null (reading 'tagName')"],
  beforeSend(event) {
    try {
      if (event.exception.values[0].stacktrace.frames[0].filename === `<anonymous>`) {
        return null;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return event;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});