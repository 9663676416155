// hooks/useCustomTranslation.js
import { useTranslation as baseUseTranslation, Trans } from 'next-i18next';
import { useTranslationToggle } from 'context/TranslationToggleContext';

const useCustomTranslation = (namespace) => {
  const base  = baseUseTranslation(namespace);
  const { i18n,ready } = base;
  const { showKeys } = useTranslationToggle();
  if(!showKeys) {
    return base;
  }
  const translate = (key, options) => {
    if(showKeys) {
      if(options?.ns) {
        return `${options.ns}.${key}`
      }
      try {
        if(typeof namespace !== 'string' && namespace?.length > 1) {
          return `${namespace[0]}.${key}` || `${namespace}.${key}`
        } else {
          return `${namespace}.${key}`
        }
      } catch (err) {
        return `${namespace}.${key}`;
      }
    }
  };
  const ret: any = [translate, i18n, ready]
  ret.t = translate;
  ret.i18n = i18n;
  ret.ready = ready;

  return ret;
};
const useTranslation = useCustomTranslation;
export {Trans, useTranslation};