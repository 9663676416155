import {CalendarDateTime, isSameDay, parseDate, parseDateTime} from '@internationalized/date';

import {DatepickerData} from 'lib/api/backend.schemas';

import {getDateInBerlinTime} from 'utils/date/formatDate';

export interface StationDatepickerData extends DatepickerData {
  partner?: boolean;
}

export const generateTimeSlots = (start, end) => {
  const timeSlots = [];
  let currentTime = new Date(start);

  while (currentTime <= end) {
    timeSlots.push(currentTime.toLocaleTimeString(['de'], {hour: '2-digit', minute: '2-digit'}));
    currentTime.setMinutes(currentTime.getMinutes() + 15);
  }
  return timeSlots;
};
export const formatCalenderDateTimeToTimeString = (date: CalendarDateTime) =>
  `${date?.hour?.toString().padStart(2, '0')}:${date?.minute?.toString().padStart(2, '0')}`;
export const getAvailableTimeSlots = (
  selectedDate,
  openingTimes,
  holidays,
  nextPossibleDeparture,
  minValue: CalendarDateTime,
  maxValue: CalendarDateTime,
) => {
  const selectedDateSameDayAsMaxValue = maxValue && isSameDay(parseDate(selectedDate), maxValue);

  const minTime = formatCalenderDateTimeToTimeString(minValue);

  const maxTime = selectedDateSameDayAsMaxValue
    ? formatCalenderDateTimeToTimeString(maxValue.subtract({minutes: 15}))
    : formatCalenderDateTimeToTimeString(maxValue);
  const selectedDay = getDateInBerlinTime(selectedDate).getDay();
  const openingTimesForDay = openingTimes[selectedDay];

  // Check if selectedDate is a holiday
  const holidayOpeningTimes = holidays?.[selectedDate];

  if (holidayOpeningTimes) {
    const startTime = new Date(`${selectedDate}T${holidayOpeningTimes.start}`);
    const endTime = new Date(`${selectedDate}T${holidayOpeningTimes.end}`);
    return categorizeTimeSlots(
      generateTimeSlots(startTime, endTime).filter(slot => {
        const slotTime = new Date(`${selectedDate}T${slot}`);
        const minDateTime = new Date(`${selectedDate}T${minTime}`);
        const maxDateTime = new Date(`${selectedDate}T${maxTime}`);

        if (minValue && isSameDay(parseDate(selectedDate), minValue)) {
          return slotTime >= minDateTime && slotTime >= new Date(nextPossibleDeparture);
        } else if (maxValue && isSameDay(parseDate(selectedDate), maxValue)) {
          return slotTime <= maxDateTime && slotTime >= new Date(nextPossibleDeparture);
        } else {
          return slotTime >= new Date(nextPossibleDeparture);
        }
      }),
    );
  } else if (openingTimesForDay) {
    const startTime = new Date(`${selectedDate}T${openingTimesForDay.start}`);
    const endTime = new Date(`${selectedDate}T${openingTimesForDay.end}`);
    if (openingTimesForDay.end === '00:00') {
      endTime.setHours(23, 45);
    }
    return categorizeTimeSlots(
      generateTimeSlots(startTime, endTime).filter(slot => {
        const slotTime = new Date(`${selectedDate}T${slot}`);
        const minDateTime = new Date(`${selectedDate}T${minTime}`);
        const maxDateTime = new Date(`${selectedDate}T${maxTime}`);

        if (minValue && maxValue && isSameDay(parseDate(selectedDate), minValue) && isSameDay(parseDate(selectedDate), maxValue)) {
          if (maxDateTime <= new Date(nextPossibleDeparture)) return slotTime.getTime() === new Date(nextPossibleDeparture).getTime();
          return slotTime >= minDateTime && slotTime <= maxDateTime && slotTime >= new Date(nextPossibleDeparture);
        } else if (minValue && isSameDay(parseDate(selectedDate), minValue)) {
          return slotTime >= minDateTime && slotTime >= new Date(nextPossibleDeparture);
        } else if (maxValue && isSameDay(parseDate(selectedDate), maxValue)) {
          return slotTime <= maxDateTime && slotTime >= new Date(nextPossibleDeparture);
        } else {
          return slotTime >= new Date(nextPossibleDeparture);
        }
      }),
    );
  } else {
    return [];
  }
};

const categorizeTimeSlots = timeSlots => {
  const categorizedTimeSlots = [
    {name: 'timeOptionsNight', timeSlots: []},
    {name: 'timeOptionsMorning', timeSlots: []},
    {name: 'timeOptionsNoon', timeSlots: []},
    {name: 'timeOptionsEvening', timeSlots: []},
  ];

  timeSlots.forEach(slot => {
    const [startHour, startMinute] = slot.split(':').map(Number);

    if (isMorning(startHour)) {
      categorizedTimeSlots[1].timeSlots.push(slot);
    } else if (isMidday(startHour)) {
      categorizedTimeSlots[2].timeSlots.push(slot);
    } else if (isEvening(startHour)) {
      categorizedTimeSlots[3].timeSlots.push(slot);
    } else {
      categorizedTimeSlots[0].timeSlots.push(slot);
    }
  });
  // console.log({categorizeTimeSlots})
  return categorizedTimeSlots;
};

const isMorning = hour => {
  return hour >= 6 && hour < 12;
};

const isMidday = hour => {
  return hour >= 12 && hour < 18;
};

const isEvening = hour => {
  return hour >= 18 && hour < 24;
};

export const getExcludedDates = (disabledDates: string[], disabledWeekdays: number[]) => {
  const currentDate = new Date();
  const endDate = new Date(currentDate);
  endDate.setFullYear(endDate.getFullYear() + 2); // Set end date to current date + 2 years

  const excludedDates = [] as string[];
  const currentDateCopy = new Date(currentDate.getTime());

  // Iterate over the date range
  while (currentDateCopy <= endDate) {
    // Check if the current date is a disabled date
    const formattedDate = currentDateCopy.toISOString().split('T')[0];
    if (disabledDates.includes(formattedDate)) {
      excludedDates.push(formattedDate);
    } else if (disabledWeekdays && disabledWeekdays.includes(currentDateCopy.getDay())) {
      // Check if disabledWeekdays exists and the current weekday is disabled
      excludedDates.push(formattedDate);
    }
    // Move to the next day
    currentDateCopy.setDate(currentDateCopy.getDate() + 1);
  }

  return excludedDates;
};

export const convertDateToCalendarDateTime = (date: Date) =>
  parseDateTime(date.toISOString().replace(/\.\d\d\dZ/gi, ''));

export function setToNext15MinSlot(date: Date, slotsToSkip = 0) {
  // Parse the given date string into a JavaScript Date object
  // Get the current minutes value
  let minutes = date.getMinutes() + slotsToSkip * 15;

  // if minutes are on edge of 15min slot, decrease a minute to include the edge
  if (minutes > 14 && minutes % 15 === 0) --minutes;

  // Calculate the difference between the current minutes and the next multiple of 15
  const diff = 15 - (minutes % 15);

  // Add the difference to the minutes value to get the rounded-up minutes
  minutes += diff;

  // If minutes exceed 59, increment hour and reset minutes
  if (minutes >= 60) {
    date.setHours(date.getHours() + 1);
    minutes -= 60;
  }

  // Set seconds and milliseconds to zero
  date.setSeconds(0);
  date.setMilliseconds(0);

  // Set the updated minutes value
  date.setMinutes(minutes);

  // Get hours and minutes in the correct format
  const hours = date.getHours().toString().padStart(2, '0');
  const roundedMinutes = minutes === 60 ? '00' : minutes.toString().padStart(2, '0'); // If minutes equals 60, set it back to 00
  // console.log("setToNext15MinSlot", `${hours}:${roundedMinutes}`, {date, slotsToSkip})
  // Concatenate hours and rounded minutes with a colon
  return `${hours}:${roundedMinutes}`;
}
