import { useTranslation} from 'hooks/translations/useCustomTranslation';
import {useEffect, useRef, useState} from 'react';
import {useRangeCalendar} from 'react-aria';
import {RangeCalendarStateOptions, useRangeCalendarState} from 'react-stately';

import {CalendarDateTime, DateValue, endOfMonth, isSameDay, today} from '@internationalized/date';
import {parseDate} from '@internationalized/date';
import {createCalendar} from '@internationalized/date';
// import ArrowForwardIosNewIcon from '@mui/icons-material/ArrowForwardIosNew';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {RangeValue} from '@react-types/shared';

import {breakpoint} from 'styles/mui/scss';

import {useStarcarDateRangePickerStateContext} from '../../../hooks/BPR/useStarcarDateRangePickerState';
import {Button} from '../Button';
import {CustomIcon} from '../CustomIcon/CustomIcon';
import {CalendarContext} from './CalendarContext';
import {CalendarGrid} from './CalendarGrid';
import {ModalHeader} from './ModalHeader';
import styles from './styles.module.scss';

function splitTitle(title) {
  const sameYearPattern = /(\p{L}+)\s*–\s*(\p{L}+) (\d{4})/u;
  const differentYearPattern = /(\p{L}+) (\d{4})\s*–\s*(\p{L}+) (\d{4})/u;

  // If both months are in the same year
  if (sameYearPattern.test(title)) {
    const match = sameYearPattern.exec(title);
    const month1 = match[1] + ' ' + match[3];
    const month2 = match[2] + ' ' + match[3];
    return {month1, month2};
  }

  // If months span two different years
  if (differentYearPattern.test(title)) {
    const match = differentYearPattern.exec(title);
    const month1 = match[1] + ' ' + match[2];
    const month2 = match[3] + ' ' + match[4];
    return {month1, month2};
  }

  return null;
}

interface IRangeCalendarProps extends RangeCalendarStateOptions {
  isSelecting?: boolean;
  setIsSelecting?: (boolean) => void;
  minValue: DateValue;
  setMinValue?: (date: DateValue) => void;
  maxValue: DateValue;
  setMaxValue?: (date: DateValue) => void;
  excludedDates?: string[];
  closeModal?: () => void;
  isWidget: boolean;
}
// Assuming your CalendarGrid works with Material-UI

export function RangeCalendar() {
  const {
    setMinValue,
    minValue,
    maxValue,
    excludedDates,
    closeModal,
    isWidget,
    isSelecting,
    setIsSelecting,
    setSelectedTimeField,
    formValues,
    calendarProps: calendarContextProps,
    locale,
    resetDateTimepickerValues,
    setMaxValue,
    state: rangePickerState,
    cellClickHandler,
    initialMaxValue,
    initialMinValue,
    maxRentalDuration,
  } = useStarcarDateRangePickerStateContext();
  const props = {
    ...calendarContextProps,
    setMinValue,
    minValue,
    maxValue,
    excludedDates,
    closeModal,
    isWidget,
    isSelecting,
    setIsSelecting,
    locale,
    allowsNonContiguousRanges: true,
    createCalendar,
  } as IRangeCalendarProps;
  const [t] = useTranslation('bpr');
  const isDateUnavailable = (date: DateValue) => {
    return (
      !props.isSelecting &&
      (date &&
      date.compare(initialMinValue) < 0 ||
      excludedDates?.some(exDate => {
        return parseDate(exDate).compare(date) === 0;
      }))
    );
  };
  const [calendarValue, setCalendarValue] = useState<RangeValue<DateValue>>(null);
  useEffect(() => {
    if (formValues?.departure && formValues?.arrival) {
      setCalendarValue({start: formValues?.departure, end: formValues?.arrival});
    }
  }, [formValues?.departure, formValues?.arrival]);
  const [isFirstSelection, setIsFirstSelection] = useState(false);
  useEffect(() => {
    if (!formValues?.departure && !formValues?.arrival && !maxRentalDuration) {
      let days = 1;
      const todayDate = today('Europe/Berlin');
      while (isDateUnavailable(todayDate.add({days}))) {
        // make sure the date is selectable as departure
        days++;
      }
      if (!isDesktop) {
        setCalendarValue({start: todayDate.add({days}), end: todayDate.add({days: days + 3})});
        setIsFirstSelection(true);
      } else {
        props.onChange({start: todayDate.add({days}), end: todayDate.add({days: days + 3})});
        setIsFirstSelection(true);
      }
    }
  }, [initialMinValue]);

  const onChangeCalendar = (value: RangeValue<DateValue>) => {
    if (isDesktop) {
      setIsFirstSelection(false)
      props.onChange(value);
    } else {
      setIsFirstSelection(false)
      setCalendarValue(value);
    }
    setTimeout(() => {
      const shouldChangeToTimeSelect = value?.start && value?.end && isDesktop;
      setMinValue(initialMinValue);
      if (isDesktop) {
        closeModal();
      }
      if (maxRentalDuration) {
        setMinValue(initialMinValue), setMaxValue(initialMaxValue), props.setIsSelecting(false);
      }

      if (shouldChangeToTimeSelect) setSelectedTimeField('departureTime');
    }, 200);
  };
  // console.log({maxValue, minValue, initialMaxValue, initialMinValue})
  let state = useRangeCalendarState({
    ...props,
    visibleDuration: {months: 2},
    pageBehavior: 'single',
    minValue,
    value: calendarValue,
    maxValue,
    isDateUnavailable,
    onChange: onChangeCalendar,

    // pageBehavior: 'visible',
  });
  // override selectFocusedDate to make sure it doesnt commit date values on blur
  state.selectFocusedDate = () => {};
  const isDesktop = useMediaQuery(`(min-width:${breakpoint.lg}px)`);

  let ref = useRef();
  let {calendarProps, prevButtonProps, nextButtonProps, title} = useRangeCalendar(
    {...props, pageBehavior: 'single', defaultFocusedValue: minValue},
    state,
    ref,
  );
  useEffect(() => {
    // STARCAR-1487
    // if the visible range start month has no valid date(the minValue is bigger than the end of the month) select the next page
    const startOfVisibleRange = new CalendarDateTime(
      endOfMonth(state.visibleRange.start).year,
      endOfMonth(state.visibleRange.start).month,
      endOfMonth(state.visibleRange.start).day,
      23,
      59,
    );

    if (!(minValue.compare(startOfVisibleRange) < 0)) {
      state.focusNextPage();
    }
  }, []);
  //   if (maxValue && state?.highlightedRange?.start) {
  //     setMaxValue(minValue.add({days: maxRentalDuration}));
  //   }
  // }, [state?.highlightedRange?.start]);

  const {month1, month2} = splitTitle(title) || {};

  return (
    <Box sx={{
      '--calendar-width': {
        xxs: 'calc((100vw - 60px))',
        xs: 'min(calc((100vw - 60px)), 540px)',
        lg: '392px',
      },
      '--calendar-cell-width': 'min(calc(var(--calendar-width) / 7), 80px)',
    }}>
    <CalendarContext.Provider value={{cellClickHandler}}>
        <ModalHeader hidden={false} isWidget={isWidget} isFirstSelection={isFirstSelection} isSelecting={isSelecting} isDesktop={isDesktop} closeModal={closeModal} />
        <Box
        className={styles.calendarInnerWrapper}
        data-testid="range-calendar-wrapper"
        data-visible-range={`${state.visibleRange.start.month}-${state.visibleRange.end.month}`}
        {...calendarProps}

        ref={ref}
      >

        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: {xxs: 'center', lg: 'flex-start'},
              flexDirection: {xxs: 'column', lg: 'row'},
            }}
          >
            <Box
              sx={{
                width: {
                  xxs: 'var(--calendar-width)',
                  xs: 'var(--calendar-width)',
                  lg: 'var(--calendar-width)',
                },
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{display: 'flex'}}>
                <button
                  type="button"
                  data-testid="calendar-last-month-button"
                  onClick={prevButtonProps.onPress as any}
                  {...prevButtonProps}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'inherit',
                    padding: 0,
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <CustomIcon
                    className="month-button-disable-events"
                    name="chevronLeft"
                    style={{height: '24px', width: '24px'}}
                  />
                </button>
                <Typography
                  sx={{lineHeight: '30px', fontSize: '20px', fontWeight: 700}}
                  flex={1}
                  textAlign="center"
                >
                  {month1}
                </Typography>
                <button
                  data-testid="calendar-next-month-button"
                  type="button"
                  onClick={nextButtonProps.onPress as any}
                  {...nextButtonProps}
                  className={styles.nextMonthButtonMobile}
                >
                  <CustomIcon
                    className="month-button-disable-events"
                    name="chevronRight"
                    style={{height: '24px', width: '24px'}}
                  />
                </button>
              </div>
              <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <CalendarGrid minValue={minValue} maxValue={maxValue} state={state} />
                <Typography
                  mt={4}
                  mb={4}
                  sx={{display: {lg: 'none'}, textDecoration: 'underline', cursor: 'pointer'}}
                  onClick={() => {
                    resetDateTimepickerValues();
                    setIsFirstSelection(true)
                    setCalendarValue(null);
                    state.setAnchorDate(null);
                    //  rangePickerState.
                  }}
                >
                  {t('resetSelection')}
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                width: {
                  xxs: 'var(--calendar-width)',
                  xs: 'var(--calendar-width)',
                  lg: 'var(--calendar-width)',
                },
                display: {xxs: 'none', lg: 'flex'},
                flexDirection: 'column',
              }}
            >
              <div style={{display: 'flex'}}>
                <Typography
                  sx={{lineHeight: '30px', fontSize: '20px', fontWeight: 700}}
                  flex={1}
                  textAlign="center"
                >
                  {month2}
                </Typography>
                <button
                  data-testid="calendar-next-month-button"
                  type="button"
                  onClick={nextButtonProps.onPress as any}
                  {...nextButtonProps}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'inherit',
                    padding: 0,
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <CustomIcon
                    className="month-button-disable-events"
                    name="chevronRight"
                    style={{height: '24px', width: '24px'}}
                  />
                </button>
              </div>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <CalendarGrid
                  minValue={minValue}
                  maxValue={maxValue}
                  state={state}
                  offset={{months: 1}}
                />
              </div>
            </Box>
          </Box>
      </Box>
    </Box>
    </CalendarContext.Provider>
    <Box className={styles.submitButtonWrapper}>
        <Button
          sx={{display: {lg: 'none'}, width: 'var(--calendar-width)'}}
          disabled={
            isSelecting || !state?.highlightedRange?.start || !state?.highlightedRange?.end
          }
          onClick={() => {
            props.onChange(calendarValue);
            closeModal();
            setSelectedTimeField('departureTime');
          }}
          fullWidth
        >
          {t('confirmSelection')}
        </Button>
      </Box>
    </Box>
  );
}

const parseTime = timeString => {
  if (timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return {hours, minutes};
  }
  return null;
};

const isTimeLessThanOrEqual = (time1, time2) => {
  return (
    time1.hours < time2.hours || (time1.hours === time2.hours && time1.minutes <= time2.minutes)
  );
};

const categorizeTimeOption = (hour, minute, departureTime, formValues, maxValue) => {
  const currentTime = {hours: hour, minutes: minute};
  const maxTime = {hours: maxValue?.hour, minutes: maxValue?.minute};

  if (
    (departureTime &&
      formValues?.departure &&
      formValues?.arrival &&
      isSameDay(formValues?.departure, formValues?.arrival) &&
      isTimeLessThanOrEqual(currentTime, departureTime)) ||
    (maxValue &&
      formValues.departure &&
      formValues.arrival &&
      isSameDay(formValues.arrival, maxValue) &&
      !isTimeLessThanOrEqual(currentTime, maxTime))
  ) {
    return null; // Return null for time slots smaller than the minimum value
  } else if (hour >= 6 && hour < 12) {
    return 'Morning';
  } else if (hour >= 12 && hour < 18) {
    return 'Midday';
  } else if (hour >= 18 && hour < 24) {
    return 'Evening';
  } else {
    return 'Night';
  }
};

export const generateArrivalTimeOptions = (formValues, maxValue) => {
  const departureTime = parseTime(formValues?.departureTime);
  const timeOptions = [
    {name: 'timeOptionsNight', timeSlots: []},
    {name: 'timeOptionsMorning', timeSlots: []},
    {name: 'timeOptionsNoon', timeSlots: []},
    {name: 'timeOptionsEvening', timeSlots: []},
  ];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const category = categorizeTimeOption(hour, minute, departureTime, formValues, maxValue);
      if (category !== null) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        const timeOption = `${formattedHour}:${formattedMinute}`;
        switch (category) {
          case 'Morning':
            timeOptions[1].timeSlots.push(timeOption);
            break;
          case 'Midday':
            timeOptions[2].timeSlots.push(timeOption);
            break;
          case 'Evening':
            timeOptions[3].timeSlots.push(timeOption);
            break;
          default:
            timeOptions[0].timeSlots.push(timeOption);
            break;
        }
      }
    }
  }
  return timeOptions;
};
