import { createContext } from 'react';


export type TVarifyTestObject = {
    id: string;
    variationId: number;
    isVariation: boolean;
}
export type TVarifyParsedObject = {
    variationId: number;
    timestamp: number;
}
export type TVarifyVariationStore = {
  [key: string]: TVarifyTestObject;
};
export type TVarifyContext = {
  experimentsStore: TVarifyVariationStore;
  initialized: boolean;
}
export const VarifyContext = createContext({experimentsStore: {}, initialized: false} as TVarifyContext);