import isEqual from 'lodash/isEqual';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import React, {useState} from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Collapse, SxProps, Typography} from '@mui/material';

import {Box} from 'components/basic-components';

import styles from './RemoteStationInformation.module.scss';
import clsx from 'clsx';

const RemoteStationInformation = ({variant = 'collapse', sx}: {variant?: 'collapse' | 'box'; sx?: SxProps}) => {
  const {t} = useTranslation(['common']);
  const [isRemoteStationInfoOpen, setIsRemoteStationInfoOpen] = useState(false);

  return variant === 'collapse' ? (
    <>
      <Box sx={{...sx}}>
        <Collapse in={isRemoteStationInfoOpen} collapsedSize={80}>
          <Box className={styles.remoteStationInfo} p={'10px 20px 0 20px'}>
            <Typography className={styles.remoteStationInfoHeadline}>
              {t('remoteStationInfoBoxHeadline')}
            </Typography>
            <Typography className={clsx({[styles.remoteStationInfoTextCollapsed]: !isRemoteStationInfoOpen})} >
              {t('remoteStationInfo')}
            </Typography>
          </Box>
        </Collapse>
        <Box
          className={styles.remoteStationInfoButton}
          onClick={() => {
            setIsRemoteStationInfoOpen(!isRemoteStationInfoOpen);
          }}
        >
          {isRemoteStationInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
      </Box>
    </>
  ) : (
    <Box className={styles.remoteStationInfo} p={3} sx={{...sx}}>
      <Typography className={styles.remoteStationInfoHeadlineBox}>
        {t('remoteStationInfoBoxHeadline')}
      </Typography>
      <Typography className={styles.remoteStationInfoText}>
        {t('remoteStationInfo')}
      </Typography>
    </Box>
  );
};

export default React.memo(RemoteStationInformation, isEqual);